#btn {
  height: 100%;
  top: 0px;
  width: 40px;
  margin-top: 0px;

  @media (max-width: 999.98px) {
    display: none;
  }

  .arrow {
    position: absolute;
    background-color: white;
    height: 45px;
  }
}
